
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OtpInputComponent from "./verification-code/OtpInputComponent.vue";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { Actions } from "@/store/enums/StoreEnums";
import { dateTimeNow } from "@/buying-teams/helpers/DateHelper";
import NotificationService from "@/buying-teams/services/NotificationService";
import { useI18n } from "vue-i18n";
import { getHidedEmail } from "@/core/helpers/GlobalHelper";

export default defineComponent({
  name: "VerificationKey",
  components: {
    OtpInputComponent
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const authControlData = store.getters.authControlConfigs;
    const verificationCode = ref("");

    const submitButton = ref<HTMLButtonElement | null>(null);
    const isResendButtonActive = ref(true);

    onMounted(async () => {
      if (!authControlData.email || !authControlData.password) {
        redirectToRouteWithName("sign-in");
      }
    });

    const getCode = (code) => {
      verificationCode.value = code;
    };

    const resendCode = async () => {
      isResendButtonActive.value = false;
      let resendData = {
        email: authControlData.email
      };

      await store.dispatch("verifyEmail", resendData)
        .then(response => {
          if (response) {
            Swal.fire({
              icon: "success",
              heightAuto: false,
              title: t('codeHasBeenSent'),
              timer: 1000,
              showConfirmButton: false
            });
          }
        })
        .catch(error => {
          console.log("error = ", error);
          NotificationService.swalError(error);
        })
        .finally(() => {
          isResendButtonActive.value = true;
        });
    };

    const login = async () => {
      let loginData = {
        email: authControlData.email,
        password: authControlData.password,
        local_time: dateTimeNow()
      };

      await store.dispatch(Actions.LOGIN, loginData);
      // TODO add login handle functionality
    };

    //Form submit function
    const onSubmitVerification = async () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (verificationCode.value) {
        let registrationData = {
          email: authControlData.email,
          password: authControlData.password,
          verification_code: verificationCode.value
        };

        await store.dispatch("registerUser", registrationData)
          .then(async res => {
            if (res) {
              Swal.fire({
                heightAuto: false,
                icon: "success",
                title: t('success')
              });

              await login();
            } else {
              Swal.fire({
                heightAuto: false,
                icon: "error",
                title: t('somethingWentWrong')
              });
            }
          })
          .catch(err => {
            console.log('error = ', err);
            NotificationService.swalError(err);
          });
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitVerification,
      getCode,
      resendCode,
      isResendButtonActive,
      submitButton,
      authControlData,
      getHidedEmail
    };
  }

});
